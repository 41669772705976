import numberToFixed from "./numberToFixed";

export default function formatPercent({
  value,
  showSymbol = true,
  fractionDigits = 4,
  isDecimal = true,
}: {
  value: number;
  showSymbol?: boolean;
  fractionDigits?: number;
  isDecimal?: boolean;
}): string {
  return [
    isDecimal
      ? numberToFixed(value * 100, fractionDigits)
          .toString()
          .replace(".", ",")
      : value.toString(),
    showSymbol && "%",
  ]
    .filter((v) => !!v)
    .join(" ");
}
