import axios from "axios";
import { BankGroup } from "@/admin/types/bank";

export default async function createBankGroup(
  payload: Pick<BankGroup, "name" | "country" | "displayOrder">
): Promise<BankGroup> {
  const { data } = await axios.post<BankGroup>("/admin/bank/group", payload);

  return data;
}
