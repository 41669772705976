import { Currency } from "@/types/currency";
import { getSymbolCurrency, numberToFixed } from "@helpers";

export default function formatMoney({
  value,
  currency = Currency.EUR,
  locale = "ru-RU",
  showSymbol = true,
  maximumFractionDigits = 6,
  spaceCurrency = true,
}: {
  value?: number | string;
  currency?: Currency;
  locale?: string;
  showSymbol?: boolean;
  spaceCurrency?: boolean;
  maximumFractionDigits?: number;
}): string {
  value = value && Number.isFinite(+value) ? +value : 0;
  value = numberToFixed(value, maximumFractionDigits);

  let num = value.toLocaleString(locale, {
    currency,
    maximumFractionDigits,
  });

  if (showSymbol) {
    num += spaceCurrency
      ? ` ${getSymbolCurrency(currency)}`
      : getSymbolCurrency(currency);
  }

  return num;
}
