import axios from "axios";
import { Bank } from "@/admin/types/bank";

type RequestPayload = Partial<
  Pick<
    Bank,
    "hidden" | "issueCardEnabled" | "inquiryEnabled" | "info" | "displayOrder"
  >
> &
  Pick<Bank, "country" | "name"> & {
    bankId: number;
    bankGroupId?: number;
    secondFactorCode?: string;
  };

export default async function updateBank({
  secondFactorCode = "",
  ...payload
}: RequestPayload): Promise<Bank> {
  const { data } = await axios.post<Bank>("/admin/bank/update", {
    ...payload,
    secondFactorCode,
  });

  return data;
}
