import axios from "axios";
import { CardBatchUpdate } from "@/types/card";

export default async function fetchBatchUpdateCardList(): Promise<
  CardBatchUpdate[]
> {
  const { data } = await axios.get("/card/batchUpdate/list");

  return data;
}
