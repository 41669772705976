export default function scrollToEl(id: string): void {
  const sectionEl = document.getElementById(id);

  if (!sectionEl) return;

  const marginTop = (document.getElementById("header")?.offsetHeight || 0) + 20;

  window.scrollTo({
    top: sectionEl.getBoundingClientRect().top + window.scrollY - marginTop,
    behavior: "smooth",
  });

  window.history.replaceState(null, document.title, `/#${id}`);
}
