import nestedFreeze from "@/helpers/nestedFreeze";

export const phoneMasks = nestedFreeze([
  {
    mask: "-0-0000-0000",
    code: "AU",
    name_en: "Australia",
    name_ru: "Австралия",
    phonePrefix: "+61",
  },
  {
    mask: "(000)000-0000",
    code: "AT",
    name_en: "Austria",
    name_ru: "Австрия",
    phonePrefix: "+43",
  },
  {
    mask: "-00-000-00-00",
    code: "AZ",
    name_en: "Azerbaijan",
    name_ru: "Азербайджан",
    phonePrefix: "+994",
  },
  {
    mask: "(000)000-000",
    code: "AL",
    name_en: "Albania",
    name_ru: "Албания",
    phonePrefix: "+355",
  },
  {
    mask: "-00-000-0000",
    code: "DZ",
    name_en: "Algeria",
    name_ru: "Алжир",
    phonePrefix: "+213",
  },
  {
    mask: "(000)000-000",
    code: "AO",
    name_en: "Angola",
    name_ru: "Ангола",
    phonePrefix: "+244",
  },
  {
    mask: "-000-000",
    code: "AD",
    name_en: "Andorra",
    name_ru: "Андорра",
    phonePrefix: "+376",
  },
  {
    mask: "000-0000",
    code: "AG",
    name_en: "Antigua & Barbuda",
    name_ru: "Антигуа и Барбуда",
    phonePrefix: "+1268",
  },
  {
    mask: "(000)000-0000",
    code: "AR",
    name_en: "Argentina",
    name_ru: "Аргентина",
    phonePrefix: "+54",
  },
  {
    mask: "-00-000-000",
    code: "AM",
    name_en: "Armenia",
    name_ru: "Армения",
    phonePrefix: "+374",
  },
  {
    mask: "-000-0000",
    code: "AW",
    name_en: "Aruba",
    name_ru: "Аруба",
    phonePrefix: "+297",
  },
  {
    mask: "-00-000-0000",
    code: "AF",
    name_en: "Afghanistan",
    name_ru: "Афганистан",
    phonePrefix: "+93",
  },
  {
    mask: "-00-000-000",
    code: "BD",
    name_en: "Bangladesh",
    name_ru: "Бангладеш",
    phonePrefix: "+880",
  },
  {
    mask: "000-0000",
    code: "BB",
    name_en: "Barbados",
    name_ru: "Барбадос",
    phonePrefix: "+1246",
  },
  {
    mask: "-0000-0000",
    code: "BH",
    name_en: "Bahrain",
    name_ru: "Бахрейн",
    phonePrefix: "+973",
  },
  {
    mask: "-000-0000",
    code: "BZ",
    name_en: "Belize",
    name_ru: "Белиз",
    phonePrefix: "+501",
  },
  {
    mask: "(000)000-000",
    code: "BE",
    name_en: "Belgium",
    name_ru: "Бельгия",
    phonePrefix: "+32",
  },
  {
    mask: "-0[0]-000-000",
    code: "MM",
    name_en: "Burma (Myanmar)",
    name_ru: "Бирма (Мьянма)",
    phonePrefix: "+95",
  },
  {
    mask: "(000)000-000",
    code: "BG",
    name_en: "Bulgaria",
    name_ru: "Болгария",
    phonePrefix: "+359",
  },
  {
    mask: "-0-000-0000",
    code: "BO",
    name_en: "Bolivia",
    name_ru: "Боливия",
    phonePrefix: "+591",
  },
  {
    mask: "-00-0000[0]",
    code: "BA",
    name_en: "Bosnia and Herzegovina",
    name_ru: "Босния и Герцеговина",
    phonePrefix: "+387",
  },
  {
    mask: "-00-0000[0]-0000",
    code: "BR",
    name_en: "Brazil",
    name_ru: "Бразилия",
    phonePrefix: "+55",
  },
  {
    mask: "-00-00-0000",
    code: "BF",
    name_en: "Burkina Faso",
    name_ru: "Буркина Фасо",
    phonePrefix: "+226",
  },
  {
    mask: "-0[0]-000-000",
    code: "BT",
    name_en: "Bhutan",
    name_ru: "Бутан",
    phonePrefix: "+975",
  },
  {
    mask: "-00-0000-0000",
    code: "GB",
    name_en: "United Kingdom",
    name_ru: "Великобритания",
    phonePrefix: "+44",
  },
  {
    mask: "(000)000-000",
    code: "HU",
    name_en: "Hungary",
    name_ru: "Венгрия",
    phonePrefix: "+36",
  },
  {
    mask: "-00-00-0000",
    code: "HT",
    name_en: "Haiti",
    name_ru: "Гаити",
    phonePrefix: "+509",
  },
  {
    mask: "-000-0000",
    code: "GY",
    name_en: "Guyana",
    name_ru: "Гайана",
    phonePrefix: "+592",
  },
  {
    mask: "(000)000-000",
    code: "GH",
    name_en: "Ghana",
    name_ru: "Гана",
    phonePrefix: "+233",
  },
  {
    mask: "-0-000-0000",
    code: "GT",
    name_en: "Guatemala",
    name_ru: "Гватемала",
    phonePrefix: "+502",
  },
  {
    mask: "-00-000-000",
    code: "GN",
    name_en: "Guinea",
    name_ru: "Гвинея",
    phonePrefix: "+224",
  },
  {
    mask: "(000[0])00[0]-000[0]",
    code: "DE",
    name_en: "Germany",
    name_ru: "Германия",
    phonePrefix: "+49",
  },
  {
    mask: "-000-00000",
    code: "GI",
    name_en: "Gibraltar",
    name_ru: "Гибралтар",
    phonePrefix: "+350",
  },
  {
    mask: "-0000-0000",
    code: "HN",
    name_en: "Honduras",
    name_ru: "Гондурас",
    phonePrefix: "+504",
  },
  {
    mask: "-0000-0000",
    code: "HK",
    name_en: "Hong Kong",
    name_ru: "Гонконг",
    phonePrefix: "+852",
  },
  {
    mask: "(000)000-0000",
    code: "GR",
    name_en: "Greece",
    name_ru: "Греция",
    phonePrefix: "+30",
  },
  {
    mask: "(000)000-000",
    code: "GE",
    name_en: "Rep. of Georgia",
    name_ru: "Грузия",
    phonePrefix: "+995",
  },
  {
    mask: "-00-00-00-00",
    code: "DK",
    name_en: "Denmark",
    name_ru: "Дания",
    phonePrefix: "+45",
  },
  {
    mask: "({8}00)000-0000",
    code: "DO",
    name_en: "Dominican Republic",
    name_ru: "Доминиканская Республика",
    phonePrefix: "+1",
  },
  {
    mask: "(000)000-0000",
    code: "EG",
    name_en: "Egypt",
    name_ru: "Египет",
    phonePrefix: "+20",
  },
  {
    mask: "-{5}0-000-0000",
    code: "IL",
    name_en: "Israel",
    name_ru: "Израиль",
    phonePrefix: "+972",
  },
  {
    mask: "(0000)000-000",
    code: "IN",
    name_en: "India",
    name_ru: "Индия",
    phonePrefix: "+91",
  },
  {
    mask: "({8}00)000-000[00]",
    code: "ID",
    name_en: "Indonesia",
    name_ru: "Индонезия",
    phonePrefix: "+62",
  },
  {
    mask: "(000)000-0000",
    code: "IQ",
    name_en: "Iraq",
    name_ru: "Ирак",
    phonePrefix: "+964",
  },
  {
    mask: "(000)000-0000",
    code: "IR",
    name_en: "Iran",
    name_ru: "Иран",
    phonePrefix: "+98",
  },
  {
    mask: "(000)000-000",
    code: "IE",
    name_en: "Ireland",
    name_ru: "Ирландия",
    phonePrefix: "+353",
  },
  {
    mask: "(000)000-000",
    code: "ES",
    name_en: "Spain",
    name_ru: "Испания",
    phonePrefix: "+34",
  },
  {
    mask: "(000)0000-000",
    code: "IT",
    name_en: "Italy",
    name_ru: "Италия",
    phonePrefix: "+39",
  },
  {
    mask: "(000)000-00-00",
    code: "KZ",
    name_en: "Kazakhstan",
    name_ru: "Казахстан",
    phonePrefix: "+7",
  },
  {
    mask: "-00-000-000",
    code: "KH",
    name_en: "Cambodia",
    name_ru: "Камбоджа",
    phonePrefix: "+855",
  },
  {
    mask: "-00-000-000",
    code: "CY",
    name_en: "Cyprus",
    name_ru: "Кипр",
    phonePrefix: "+357",
  },
  {
    mask: "(000)000-000",
    code: "KG",
    name_en: "Kyrgyzstan",
    name_ru: "Киргизия",
    phonePrefix: "+996",
  },
  {
    mask: "(000)0000-000[00]",
    code: "CN",
    name_en: "China (PRC)",
    name_ru: "Китайская Н.Р.",
    phonePrefix: "+86",
  },
  {
    mask: "(000)000-0000",
    code: "CO",
    name_en: "Colombia",
    name_ru: "Колумбия",
    phonePrefix: "+57",
  },
  {
    mask: "-0000-0000",
    code: "CR",
    name_en: "Costa Rica",
    name_ru: "Коста-Рика",
    phonePrefix: "+506",
  },
  {
    mask: "-00-000-000",
    code: "CI",
    name_en: "Cote d’Ivoire",
    name_ru: "Кот-д’Ивуар",
    phonePrefix: "+225",
  },
  {
    mask: "-0-000-0000",
    code: "CU",
    name_en: "Cuba",
    name_ru: "Куба",
    phonePrefix: "+53",
  },
  {
    mask: "-0000-0000",
    code: "KW",
    name_en: "Kuwait",
    name_ru: "Кувейт",
    phonePrefix: "+965",
  },
  {
    mask: "-00-000-000",
    code: "LV",
    name_en: "Latvia",
    name_ru: "Латвия",
    phonePrefix: "+371",
  },
  {
    mask: "-0[0]-000-000",
    code: "LB",
    name_en: "Lebanon",
    name_ru: "Ливан",
    phonePrefix: "+961",
  },
  {
    mask: "(000)00-000",
    code: "LT",
    name_en: "Lithuania",
    name_ru: "Литва",
    phonePrefix: "+370",
  },
  {
    mask: "-00-000[0]-0000",
    code: "MY",
    name_en: "Malaysia",
    name_ru: "Малайзия",
    phonePrefix: "+60",
  },
  {
    mask: "-000-0000",
    code: "MV",
    name_en: "Maldives",
    name_ru: "Мальдивы",
    phonePrefix: "+960",
  },
  {
    mask: "-0000-0000",
    code: "MT",
    name_en: "Malta",
    name_ru: "Мальта",
    phonePrefix: "+356",
  },
  {
    mask: "-00-0000-000",
    code: "MA",
    name_en: "Morocco",
    name_ru: "Марокко",
    phonePrefix: "+212",
  },
  {
    mask: "(000)000-0000",
    code: "MX",
    name_en: "Mexico",
    name_ru: "Мексика",
    phonePrefix: "+52",
  },
  {
    mask: "-0000-0000",
    code: "MD",
    name_en: "Moldova",
    name_ru: "Молдова",
    phonePrefix: "+373",
  },
  {
    mask: "(00[0])000-000",
    code: "MC",
    name_en: "Monaco",
    name_ru: "Монако",
    phonePrefix: "+377",
  },
  {
    mask: "-00-00-0000",
    code: "MN",
    name_en: "Mongolia",
    name_ru: "Монголия",
    phonePrefix: "+976",
  },
  {
    mask: "-00-000-000",
    code: "NP",
    name_en: "Nepal",
    name_ru: "Непал",
    phonePrefix: "+977",
  },
  {
    mask: "-00-000-0000",
    code: "NL",
    name_en: "Netherlands",
    name_ru: "Нидерланды",
    phonePrefix: "+31",
  },
  {
    mask: "-0000-0000",
    code: "NI",
    name_en: "Nicaragua",
    name_ru: "Никарагуа",
    phonePrefix: "+505",
  },
  {
    mask: "-00-000-0000",
    code: "AE",
    name_en: "United Arab Emirates",
    name_ru: "Объединенные Арабские Эмираты",
    phonePrefix: "+971",
  },
  {
    mask: "(000)000-0000",
    code: "PK",
    name_en: "Pakistan",
    name_ru: "Пакистан",
    phonePrefix: "+92",
  },
  {
    mask: "(000)000-000",
    code: "PE",
    name_en: "Peru",
    name_ru: "Перу",
    phonePrefix: "+51",
  },
  {
    mask: "(000)000-000",
    code: "PL",
    name_en: "Poland",
    name_ru: "Польша",
    phonePrefix: "+48",
  },
  {
    mask: "-00-000-0000",
    code: "PT",
    name_en: "Portugal",
    name_ru: "Португалия",
    phonePrefix: "+351",
  },
  {
    mask: "-00-000-0000",
    code: "KR",
    name_en: "Korea (South)",
    name_ru: "Респ. Корея",
    phonePrefix: "+82",
  },
  {
    mask: "-00-000-000",
    code: "MK",
    name_en: "Republic of Macedonia",
    name_ru: "Респ. Македония",
    phonePrefix: "+389",
  },
  {
    mask: "-00-000-0000",
    code: "RO",
    name_en: "Romania",
    name_ru: "Румыния",
    phonePrefix: "+40",
  },
  {
    mask: "(000)000-0000",
    code: "US",
    name_en: "USA",
    name_ru: "США",
    phonePrefix: "+1",
  },
  {
    mask: "-00-000-0000",
    code: "RS",
    name_en: "Serbia",
    name_ru: "Сербия",
    phonePrefix: "+381",
  },
  {
    mask: "(000)000-000",
    code: "SK",
    name_en: "Slovakia",
    name_ru: "Словакия",
    phonePrefix: "+421",
  },
  {
    mask: "-00-000-000",
    code: "SI",
    name_en: "Slovenia",
    name_ru: "Словения",
    phonePrefix: "+386",
  },
  {
    mask: "-00-000-0000",
    code: "TJ",
    name_en: "Tajikistan",
    name_ru: "Таджикистан",
    phonePrefix: "+992",
  },
  {
    mask: "-00-000-000[0]",
    code: "TH",
    name_en: "Thailand",
    name_ru: "Таиланд",
    phonePrefix: "+66",
  },
  {
    mask: "-0-000-0000",
    code: "TM",
    name_en: "Turkmenistan",
    name_ru: "Туркменистан",
    phonePrefix: "+993",
  },
  {
    mask: "(000)000-0000",
    code: "TR",
    name_en: "Turkey",
    name_ru: "Турция",
    phonePrefix: "+90",
  },
  {
    mask: "-00-000-0000",
    code: "UZ",
    name_en: "Uzbekistan",
    name_ru: "Узбекистан",
    phonePrefix: "+998",
  },
  {
    mask: "(00)000-00-00",
    code: "UA",
    name_en: "Ukraine",
    name_ru: "Украина",
    phonePrefix: "+380",
  },
  {
    mask: "(000)000-0000",
    code: "PH",
    name_en: "Philippines",
    name_ru: "Филиппины",
    phonePrefix: "+63",
  },
  {
    mask: "(000)000-00-00",
    code: "FI",
    name_en: "Finland",
    name_ru: "Финляндия",
    phonePrefix: "+358",
  },
  {
    mask: "(000)000-000",
    code: "FR",
    name_en: "France",
    name_ru: "Франция",
    phonePrefix: "+33",
  },
  {
    mask: "-(00)-000-000[0]",
    code: "HR",
    name_en: "Croatia",
    name_ru: "Хорватия",
    phonePrefix: "+385",
  },
  {
    mask: "-00-000-000",
    code: "ME",
    name_en: "Montenegro",
    name_ru: "Черногория",
    phonePrefix: "+382",
  },
  {
    mask: "(000)000-000",
    code: "CZ",
    name_en: "Czech Republic",
    name_ru: "Чехия",
    phonePrefix: "+420",
  },
  {
    mask: "-0-0000-0000",
    code: "CL",
    name_en: "Chile",
    name_ru: "Чили",
    phonePrefix: "+56",
  },
  {
    mask: "-00-000-0000",
    code: "CH",
    name_en: "Switzerland",
    name_ru: "Швейцария",
    phonePrefix: "+41",
  },
  {
    mask: "-00-000-0000",
    code: "SE",
    name_en: "Sweden",
    name_ru: "Швеция",
    phonePrefix: "+46",
  },
  {
    mask: "-00-000-0000",
    code: "LK",
    name_en: "Sri Lanka",
    name_ru: "Шри-Ланка",
    phonePrefix: "+94",
  },
  {
    mask: "-000[0]-0000",
    code: "EE",
    name_en: "Estonia",
    name_ru: "Эстония",
    phonePrefix: "+372",
  },
]);
