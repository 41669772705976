import { Role } from "@/types/role";

export function cleanRole(value: Role): Role {
  return value.replace("ROLE_", "") as Role;
}

export function hasRole(userRole: Role, matchRole: Role | Role[]): boolean {
  if (Array.isArray(matchRole)) {
    return matchRole.some((role) => cleanRole(userRole) === cleanRole(role));
  }

  return cleanRole(userRole) === cleanRole(matchRole);
}
