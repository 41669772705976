export default function checkPassword(password: string): boolean {
  return (
    password.length >= 8 &&
    password.length <= 20 &&
    /[0-9]/.test(password) &&
    /[a-z]/i.test(password) &&
    /[A-Z]/.test(password) &&
    /^[a-zA-Z0-9]+$/g.test(password)
  );
}
