export default function getInitials(value: string): string {
  return value
    .split(" ")
    .reduce<string>((initials, word) => {
      word = word.trim();

      if (word.length && initials.length < 2) {
        initials += word[0];
      }

      return initials;
    }, "")
    .toUpperCase();
}
