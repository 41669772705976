module.exports = {
  IS_PROD: process.env.NODE_ENV === "production",
  APP_BAR_HEIGHT: 72,
  MOBILE_APP_BAR_HEIGHT: 48,
  MOBILE_BREAKPOINT: 1280,
  SCROLLBAR_WIDTH: 16,
  ISSUE_CARD_FORM_BODY_CLASSNAME: "issue-card-form-mode",
  ISSUE_CARD_CONFIRM_BODY_CLASSNAME: "issue-card-confirm-mode",
  WELCOME_SCREEN_BODY_CLASSNAME: "welcome-screen-mode",
};
