import axios from "axios";
import { Currency } from "@/types/currency";

interface Response {
  declineRate: number;
  declineAmount: number;
}

export default async function filterStatisticDeclines(payload: {
  currency?: Currency[];
  userEmails?: string[];
  cardIds?: number[];
  bankIds?: number[];
}): Promise<Response> {
  const { data } = await axios.post<Response>(
    "/statistic/declines/filter",
    payload
  );

  return data;
}
