import axios from "axios";

export default async function confirmEmailResend({
  email,
}: {
  email: string;
}): Promise<void> {
  const { data } = await axios.post("/auth/email/confirm/resend", { email });

  return data;
}
