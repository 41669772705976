import axios from "axios";
import { AccountingLevel } from "@/types/accounting";

export default async function updateAccounting(payload: {
  userId: number;
  level?: AccountingLevel;
  tags?: string[];
  wrote?: boolean;
  feedback?: boolean;
  comment?: string;
}): Promise<{
  token: string;
  expires: number;
}> {
  const { data } = await axios.post("/admin/accounting/update", payload);

  return data;
}
