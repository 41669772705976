import axios from "axios";
import { ApiPageRequest, ApiPageResponse } from "@admin/types/api";
import { Currency } from "@admin/types/currency";
import { ExchangeLog } from "@admin/types/exchangeLog";

export default async function fetchExchangeLogList({
  cancelToken,
  ...payload
}: ApiPageRequest & {
  periodStart?: number;
  periodEnd?: number;
  email?: string;
  fromCurrencies?: Currency[];
}) {
  const { data } = await axios.post<ApiPageResponse<ExchangeLog>>(
    "/admin/exchangeLog/list",
    payload,
    {
      cancelToken,
    }
  );

  return data;
}
