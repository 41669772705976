export enum ApiError {
  SECOND_FACTOR_REQUIRED = "SECOND_FACTOR_REQUIRED",
  ACCESS_DENIED = "ACCESS_DENIED",
  USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
  USER_NOT_ACTIVE = "User not active",
  ACCOUNT_DISABLED = "ACCOUNT_DISABLED",
  ACCOUNT_NOT_VERIFIED = "ACCOUNT_NOT_VERIFIED",
  ACCOUNT_DISABLED_BY_ADMIN = "ACCOUNT_DISABLED_BY_ADMIN",
  ACCOUNT_DISABLED_BY_TEAMLEAD = "ACCOUNT_DISABLED_BY_TEAMLEAD",
  INVALID_SECOND_FACTOR = "INVALID_SECOND_FACTOR",
  TOO_WEAK_PASSWORD = "TOO_WEAK_PASSWORD",
  TOO_MANY_CARD_ISSUED = "TOO_MANY_CARD_ISSUED",
  TOO_MANY_POSTPAID_CARD_ISSUED = "TOO_MANY_POSTPAID_CARD_ISSUED",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  INTERNAL_ERROR_ADDITIONAL = "Internal Error",
  INVALID_CAPTCHA = "INVALID_CAPTCHA",
  INVALID_INVITE = "INVALID_INVITE",
  INVALID_SESSION = "INVALID_SESSION",
  INVALID_ARGUMENTS = "INVALID_ARGUMENTS",
  SMS_CONFIRMATION_VERIFY_FAILED = "SMS_CONFIRMATION_VERIFY_FAILED",
  SMS_VERIFICATION_MAX_ATTEMPTS_ERROR = "SMS_VERIFICATION_MAX_ATTEMPTS_ERROR",
  BANK_INQUIRY_DISABLED = "BANK_INQUIRY_DISABLED",
  RECAPTCHA_ATTEMPT_FAILED = "RECAPTCHA_ATTEMPT_FAILED",
  ACCOUNT_EMAIL_NOT_VERIFIED = "ACCOUNT_EMAIL_NOT_VERIFIED",
  ACCOUNT_EMAIL_ALREADY_VERIFIED = "ACCOUNT_EMAIL_ALREADY_VERIFIED",
  ACCOUNT_EMAIL_VERIFICATION_EXPIRED = "ACCOUNT_EMAIL_VERIFICATION_EXPIRED",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  MERCHANT_ERROR = "MERCHANT_ERROR",
  PROMOCODE_NOT_VALID = "PROMOCODE_NOT_VALID",
  PROMOCODE_ACCESS_BLOCKED = "PROMOCODE_ACCESS_BLOCKED",
}

export interface ApiErrorResponse {
  success: boolean;
  error: ApiError;
  error_code: number;
}
