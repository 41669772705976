import axios from "axios";

export default async function payoutReferral(payload: {
  userIds: number[];
  payoutBefore?: string;
}): Promise<void> {
  const { data } = await axios.post("/admin/referral/payout", payload);

  return data;
}
