import axios from "axios";
import { News } from "@/admin/types/news";

export default async function fetchNews(): Promise<News[]> {
  const {
    data: { items },
  } = await axios.get<{ items: News[] }>("/admin/news");

  return items.sort((a, b) => {
    const currentPublishAfter = new Date(a.publishAfter).getTime();
    const nextPublishAfter = new Date(b.publishAfter).getTime();

    return nextPublishAfter - currentPublishAfter;
  });
}
