import axios from "axios";
import { News } from "@/admin/types/news";

export default async function fetchNewsById(
  id: number
): Promise<News | undefined> {
  const {
    data: { items },
  } = await axios.get<{ items: News[] }>("/admin/news");

  return items.find((item) => item.id === id);
}
