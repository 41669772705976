import axios from "axios";
import { CardBatchUpdate } from "@/types/card";

export default async function batchUpdateCardEdit(payload: {
  id: string;
  isClosed?: boolean;
  isCanceled?: boolean;
}): Promise<CardBatchUpdate> {
  const { data } = await axios.post("/card/batchUpdate/update", payload);

  return data;
}
