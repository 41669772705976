import axios from "axios";

export default async function checkPromocode({
  promocode,
}: {
  promocode: string;
}): Promise<boolean> {
  const { data: checked } = await axios.post("/auth/promocode/check", {
    promocode,
  });

  return checked ? Promise.resolve(true) : Promise.reject(false);
}
