import axios from "axios";
import {
  Transaction,
  TransactionType,
  TransactionStatus,
} from "@/types/transaction";
import { Currency } from "@/types/currency";

export default async function fetchTransactions(payload: {
  periodStart: number;
  periodEnd: number;
  userEmails?: string[];
  cardIds?: number[];
  cardGroupIds?: (number | null)[];
  receiptIds?: string[];
  currencies?: Currency[];
  types?: TransactionType[];
  statuses?: TransactionStatus[];
  limit?: number;
  offset?: number;
  textQuery?: string;
  sort?: string[];
}) {
  const { data } = await axios.post<{ items: Transaction[]; total: number }>(
    "/transaction/pageable",
    payload
  );

  return data;
}
