import axios from "axios";
import { News } from "@admin/types/news";

export default async function updateNews({
  id: newsId,
  content,
  title,
  ...payload
}: Omit<News, "createdAt" | "isActive" | "content" | "title"> & {
  title: Record<string, string>;
  content: Record<string, string>;
} & {
  active: boolean;
}): Promise<News> {
  const { data } = await axios.post(
    "/admin/news/update",
    {
      ...payload,
      content,
      title,
    },
    {
      params: {
        newsId,
      },
    }
  );

  return data;
}
