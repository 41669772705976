const {
  IS_PROD,
  APP_BAR_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
  MOBILE_BREAKPOINT,
  SCROLLBAR_WIDTH,
  ISSUE_CARD_FORM_BODY_CLASSNAME,
  ISSUE_CARD_CONFIRM_BODY_CLASSNAME,
  WELCOME_SCREEN_BODY_CLASSNAME,
}: {
  IS_PROD: boolean;
  APP_BAR_HEIGHT: number;
  MOBILE_APP_BAR_HEIGHT: number;
  MOBILE_BREAKPOINT: number;
  SCROLLBAR_WIDTH: number;
  ISSUE_CARD_FORM_BODY_CLASSNAME: string;
  ISSUE_CARD_CONFIRM_BODY_CLASSNAME: string;
  WELCOME_SCREEN_BODY_CLASSNAME: string;
} = require("../../commonConfig");

export const APP_FRONT_ENV = process.env.VUE_APP_FRONT_ENV || "development";
export const ENABLED_SENTRY = APP_FRONT_ENV === "production";
export const DOMAIN = "multicards.io";
export const ORIGIN = `https://${DOMAIN}`;

export const MIN_AUTO_REFILL_THRESHOLD = 1;
export const MAX_AUTO_REFILL_THRESHOLD = 10000;

export const MIN_AUTO_REFILL_AMOUNT = 10;
export const MAX_AUTO_REFILL_AMOUNT = 10000;

export const MIN_AMOUNT_REFILL_CARD = 10;
export const MIN_AMOUNT_LIMIT = 10;
export const MIN_NUM_CARDS_BATCH_ISSUE = 1;
export const MAX_NUM_CARDS_BATCH_ISSUE = 50;
export const COPYRIGHT =
  "© 2023 Multicards. All rights reserved.<br />DIGITORY GROUP PC, United Kingdom.";

export const accessFacebookCodeNotifications = [
  "test@test.ru",
  "rav@madcat.media",
];

export {
  IS_PROD,
  APP_BAR_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
  MOBILE_BREAKPOINT,
  SCROLLBAR_WIDTH,
  ISSUE_CARD_FORM_BODY_CLASSNAME,
  ISSUE_CARD_CONFIRM_BODY_CLASSNAME,
  WELCOME_SCREEN_BODY_CLASSNAME,
};
