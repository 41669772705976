export default function isMaskedValue(
  value: string | null,
  type: "cardNumber"
): boolean {
  if (typeof value !== "string") {
    return false;
  }

  if (type === "cardNumber") {
    return !/^\d+$/.test(value);
  }

  return false;
}
