import { PhoneNumber } from "@/types/phoneNumber";
import IMask from "imask";
import { phoneMasks } from "@config/phone";

export default function formatPhoneNumber({
  prefix,
  country,
  number,
}: Partial<PhoneNumber>): string {
  if (!number) {
    return "";
  }

  const phoneMask = phoneMasks.find(
    ({ phonePrefix, code }) => phonePrefix === prefix && country === code
  );

  if (!phoneMask?.mask) {
    return `${prefix} ${number}`;
  }

  const masked = IMask.createMask({
    mask: phoneMask.mask,
  });

  masked.resolve(number);

  return `${prefix} ${masked.value}`;
}
