export default function declensionOfNouns(
  value: number,
  words: string[]
): string {
  value = Math.abs(value) % 100;

  if (value > 10 && value < 20) {
    return words[2];
  }

  value %= 10;

  if (value > 1 && value < 5) {
    return words[1];
  }

  if (value == 1) {
    return words[0];
  }

  return words[2];
}
