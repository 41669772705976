import axios from "axios";

export default async function ghostLogin({
  email,
}: {
  email: string;
}): Promise<{
  token: string;
  expires: number;
}> {
  const { data } = await axios.post("/admin/ghost/login", { email });

  return data;
}
