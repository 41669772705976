import axios from "axios";
import { Partner } from "@admin/types/partner";

export default async function fetchPartners(): Promise<Partner[]> {
  const {
    data: { items },
  } = await axios.get<{ items: Partner[] }>("/admin/partners/list");

  items.sort((a, b) => a.displayOrder - b.displayOrder);

  return items;
}
