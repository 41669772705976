import { Currency } from "@/types/currency";

export default function getSymbolCurrency(
  currency: Currency = Currency.EUR
): string {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (currency === "TRY") {
    return "₺";
  }

  return (0)
    .toLocaleString("ru-RU", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .slice(2);
}
