import axios from "axios";
import { ApiPageRequest, ApiPageResponse } from "@/admin/types/api";
import { AccountingItem } from "@/types/accounting";
import { AccountingSortField, SortDirection } from "@admin/types/sort";

export default async function searchAccounting({
  cancelToken,
  userIds,
  userTelegramIds,
  userEmails,
  levels,
  tags,
  sortBy,
  sortDirection,
  ...payload
}: ApiPageRequest & {
  userIds?: number[];
  userTelegramIds?: string[];
  userEmails?: string[];
  levels?: string[];
  tags?: string[];
  sortBy?: AccountingSortField;
  sortDirection?: SortDirection;
}): Promise<ApiPageResponse<AccountingItem>> {
  const { data } = await axios.post(
    "/admin/accounting/search",
    {
      ...payload,
      sortBy,
      sortDirection,
      userIds: userIds?.length ? userIds : undefined,
      userTelegramIds: userTelegramIds?.length ? userTelegramIds : undefined,
      userEmails: userEmails?.length ? userEmails : undefined,
      levels: levels?.length ? levels : undefined,
      tags: tags?.length ? tags : undefined,
    },
    {
      cancelToken,
    }
  );

  return data;
}
