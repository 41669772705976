export default function forOwn<O>(
  object: O | null | undefined,
  callback: (value: O[keyof O], key: keyof O) => void
): void {
  if (!object) return;

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key];

      callback(value, key);
    }
  }
}
