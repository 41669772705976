import axios from "axios";
import { ReferralStatistic } from "@/types/referral";

type Response = ReferralStatistic[];

export default async function fetchReferralStatistic(): Promise<Response> {
  const { data } = await axios.get<Response>("/referral/statistic");

  return data;
}
