import axios from "axios";
import { Partner } from "@admin/types/partner";

export default async function createPartner(
  payload: Partial<Omit<Partner, "id">>
): Promise<Partner> {
  const { data } = await axios.post<Partner>("/admin/partners/create", payload);

  return data;
}
