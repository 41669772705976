export default function hashStringToColor(value?: string | number): string {
  if (!value) return "";

  const str = value.toString();

  const color = (v: number) => `0${v.toString(16)}`.slice(-2);

  let hash = 280;

  for (let index = 0; index < str.length; index++) {
    hash = (hash << 16) + hash + str.charCodeAt(index);
  }

  const r = color((hash & 0xff0000) >> 16);
  const g = color((hash & 0x00ff00) >> 8);
  const b = color(hash & 0x0000ff);

  return `#${r}${g}${b}`;
}
