import { namespace } from "vuex-class";

export const sidebarModule = namespace("sidebar");
export const statisticModule = namespace("statistic");
export const authModule = namespace("auth");
export const cardModule = namespace("card");
export const cardGroupModule = namespace("card/group");
export const profileModule = namespace("profile");
export const bankModule = namespace("bank");
export const teamModule = namespace("team");
export const billingModule = namespace("billing");
export const walletModule = namespace("wallet");
export const cashflowModule = namespace("cashflow");
export const blockchainModule = namespace("blockchain");
export const userModule = namespace("user");
export const faqModule = namespace("faq");
export const messageModule = namespace("message");
export const newsModule = namespace("news");
export const partnerModule = namespace("partner");
export const warningModule = namespace("warning");
