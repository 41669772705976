export default function parsedDate(
  value: string | number | Date,
  { showTime }: { showTime?: boolean } = {
    showTime: false,
  }
): Date {
  let dateVal = value;

  if (typeof dateVal === "string" && showTime) {
    const [date, time] = dateVal.split(" ");

    if (time) {
      dateVal = new Date(date);

      const [hours, minutes, seconds] = time.split(":");

      dateVal.setHours(+hours);
      dateVal.setMinutes(+minutes);
      dateVal.setSeconds(+seconds);
    }
  }

  return new Date(dateVal);
}
