import axios from "axios";
import { Partner } from "@admin/types/partner";

export default async function updatePartner({
  id,
  ...payload
}: Pick<Partner, "id"> & Partial<Omit<Partner, "id">>): Promise<Partner> {
  const { data } = await axios.post<Partner>(
    "/admin/partners/update",
    payload,
    {
      params: {
        id,
      },
    }
  );

  return data;
}
