import parsedDate from "./parsedDate";

export default function formatDate(
  value: string | number | Date,
  options: {
    locale?: string;
    showTime?: boolean;
    showDate?: boolean;
    month?: "2-digit" | "numeric" | "long" | "short" | "narrow";
  } = {}
): string {
  const {
    locale = "ru-RU",
    showTime = false,
    showDate = true,
    month = "2-digit",
  } = options;

  return parsedDate(value, { showTime }).toLocaleString(locale, {
    day: showDate ? "2-digit" : undefined,
    month: showDate ? month : undefined,
    year: showDate ? "numeric" : undefined,
    hour: showTime ? "2-digit" : undefined,
    minute: showTime ? "2-digit" : undefined,
    timeZone: "UTC",
    timeZoneName: showTime ? "short" : undefined,
  });
}
