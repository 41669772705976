import axios from "axios";
import { Promocode } from "@admin/types/promocode";

export default async function createPromocode(
  payload: Partial<Omit<Promocode, "id" | "amountUsed">>
): Promise<Promocode> {
  const { data } = await axios.post<Promocode>(
    "/admin/promocode/create",
    payload
  );

  return data;
}
