import axios from "axios";

export default async function suspendCard({
  cardId,
  secondFactorCode = "",
}: {
  cardId: number;
  secondFactorCode?: string;
}): Promise<void> {
  await axios.post("/admin/card/suspend", { cardId, secondFactorCode });
}
