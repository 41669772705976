export default function findInObject<O extends Record<string, unknown>>(
  object: O | null | undefined,
  callback: (value: O[keyof O], key: string) => boolean
):
  | {
      key: Extract<keyof O, string>;
      value: O[keyof O] | undefined;
    }
  | undefined {
  if (!object) return;

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key];
      const finded = callback(value, key);

      if (finded) {
        return {
          key,
          value,
        };
      }
    }
  }
}
