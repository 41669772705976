import axios from "axios";
import { getFullName } from "@helpers";
import { TeamMember } from "@/types/team";

export default async function fetchTeamMembers(): Promise<TeamMember[]> {
  const { data } = await axios.get<TeamMember[]>("/team/member");

  data.sort((a, b) => {
    const firstFullName = getFullName({
      firstName: a.firstName,
      lastName: a.lastName,
      fallback: a.email,
    }).toLowerCase();

    const secondFullName = getFullName({
      firstName: b.firstName,
      lastName: b.lastName,
      fallback: b.email,
    }).toLowerCase();

    if (a.enabled && !b.enabled) {
      return -1;
    }

    if (!a.enabled && b.enabled) {
      return 1;
    }

    if (firstFullName < secondFullName) {
      return -1;
    }

    if (firstFullName > secondFullName) {
      return 1;
    }

    return 0;
  });

  return data;
}
