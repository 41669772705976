import axios from "axios";
import { NetworkRefill } from "@admin/types/network";

export default async function updateNetworkUpdate(
  payload: Pick<NetworkRefill, "id"> & Partial<Pick<NetworkRefill, "comment">>
) {
  const { data } = await axios.post("/admin/network/refills/update", payload);

  return data;
}
