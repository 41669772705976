import axios from "axios";

export default async function deletePromocode({
  id,
}: {
  id: number;
}): Promise<void> {
  const { data } = await axios.delete(`/admin/promocode/${id}`);

  return data;
}
