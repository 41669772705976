import axios from "axios";
import { ApiPageRequest, ApiPageResponse } from "@admin/types/api";
import { LogItem, LogEvent } from "@admin/types/log";

export default async function requestLogs(
  payload: ApiPageRequest & {
    events?: LogEvent[];
    initiatorIds?: number[];
  }
): Promise<ApiPageResponse<LogItem>> {
  const { data } = await axios.post("/admin/log/request", payload);

  return data;
}
