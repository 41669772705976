import decodeHtmlEntities from "./decodeHtmlEntities";

export default function cleanHtml(value: string): string {
  const containerEl = document.createElement("div");

  containerEl.innerHTML = decodeHtmlEntities(value);
  containerEl.querySelectorAll("script").forEach((script) => script.remove());

  return containerEl.innerHTML;
}
