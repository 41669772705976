import isEmpty from "./isEmpty";

export default function decodeHtmlEntities(value: string): string {
  if (isEmpty(value)) return "";

  const textareaEl = document.createElement("textarea");

  textareaEl.innerHTML = value;

  return textareaEl.value;
}
