import axios from "axios";
import { BankGroup } from "@/admin/types/bank";

export default async function updateBankGroup({
  id,
  ...payload
}: Pick<BankGroup, "id"> &
  Partial<
    Pick<BankGroup, "name" | "country" | "displayOrder">
  >): Promise<BankGroup> {
  const { data } = await axios.post<BankGroup>(
    `/admin/bank/group/${id}`,
    payload
  );

  return data;
}
