export default function nestedFreeze<T>(value: T): T {
  if (!value) return value;

  if (Array.isArray(value)) {
    value.forEach((item) => {
      Object.freeze(item);
    });

    return value;
  }

  return Object.freeze(value);
}
