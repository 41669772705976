import isEmpty from "./isEmpty";

export default function getFullName({
  firstName,
  lastName,
  fallback,
}: {
  firstName?: string;
  lastName?: string;
  fallback?: string;
}): string {
  const str: string[] = [];

  if (firstName && !isEmpty(firstName)) {
    str.push(firstName.trim());
  }

  if (lastName && !isEmpty(lastName)) {
    str.push(lastName.trim());
  }

  if (!str.length && fallback && !isEmpty(fallback)) {
    str.push(fallback);
  }

  return str.join(" ");
}
