export default function htmlToText(htmlStr: string): string {
  const container = document.createElement("div");

  container.innerHTML = htmlStr;

  container.querySelectorAll("br, div, p").forEach((brEl) => {
    brEl.insertAdjacentText("afterend", "\n");
    brEl.insertAdjacentText("afterend", brEl.textContent || "");
    brEl.remove();
  });

  return container.innerText
    .split(" ")
    .filter((w) => w.trim().length)
    .join(" ");
}
