import axios from "axios";
import { ReferralStatistic } from "@admin/types/referral";

export default async function fetchReferralStatistic(): Promise<
  ReferralStatistic[]
> {
  const { data } = await axios.get<ReferralStatistic[]>(
    "/admin/referral/statistic"
  );

  return data;
}
