import axios from "axios";
import { News } from "@admin/types/news";

export default async function createNews(
  payload: Omit<News, "id" | "createdAt" | "isActive" | "content" | "title"> & {
    title: Record<string, string>;
    content: Record<string, string>;
  } & {
    active: boolean;
  }
): Promise<News> {
  const { data } = await axios.post<News>("/admin/news", payload);

  return data;
}
