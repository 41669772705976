import axios from "axios";
import { Promocode, PromocodeType } from "@admin/types/promocode";
import { Currency } from "@admin/types/currency";

export default async function batchCreatePromocodes(payload: {
  num: number;
  type: PromocodeType;
  bonus: number;
  currency?: Currency;
  prefix: string;
}): Promise<Promocode> {
  const { data } = await axios.post<Promocode>(
    "/admin/promocode/create/batch",
    payload
  );

  return data;
}
