import axios from "axios";
import { Partner } from "@admin/types/partner";
import { decodeHtmlEntities } from "@helpers";

export default async function fetchPartner(id: number): Promise<Partner> {
  const {
    data: { items },
  } = await axios.get<{ items: Partner[] }>("/admin/partners/list");

  const partner = items.find((item) => item.id === id);

  if (!partner) {
    throw `Не найден партнер с ID ${id}`;
  }

  partner.link = decodeHtmlEntities(partner.link);

  return partner;
}
