import axios from "axios";
import { Promocode } from "@admin/types/promocode";

export default async function updatePromocode({
  id,
  ...payload
}: Pick<Promocode, "id"> &
  Partial<Omit<Promocode, "id" | "amountUsed" | "type">>): Promise<Promocode> {
  const { data } = await axios.post<Promocode>(
    `/admin/promocode/${id}`,
    payload
  );

  return data;
}
